var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"BeneficiariosForm empresas__inner container mt24 mb24"},[_c('ButtonBackHome'),_c('header',{staticClass:"empresas__header"},[_c('h2',{staticClass:"empresas_home__heading heading"},[_vm._v("Tus beneficiarios")]),_c('div',{staticClass:"empresas_home__text"},[_vm._v(" Estos son tus empleados que actualmente cuentan con beneficios."),_c('br'),_vm._v(" Para poder registrar a los miembros de su familia, para que ellos también tengan los beneficios en los servicios de Cruz Roja Mexicana Chihuahua, tu empleado deberá usar el código de 6 caracteres que se le asignó en "),_c('a',{staticStyle:{"color":"#ed1b2e"},attrs:{"href":"https://cruzrojachihuahua.org/#/beneficiario","target":"_blank"},domProps:{"textContent":_vm._s('https://cruzrojachihuahua.org/#/beneficiario')}})])]),_c('v-card',{attrs:{"flat":"","disabled":_vm.cargando,"loading":_vm.cargando}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Buscar","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"upper",attrs:{"headers":_vm.headers,"items":_vm.beneficiarios,"search":_vm.search,"loading":_vm.cargando,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"item.codigo",fn:function(ref){
var item = ref.item;
return [(item.familia === 'si')?_c('span',{domProps:{"textContent":_vm._s(item.codigo)}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{domProps:{"textContent":_vm._s('group')}})],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('more_vert')}})],1)]}}],null,true)},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{on:{"click":function($event){_vm.idBenToDelete = item.id}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Eliminar beneficiario')}})],1)],1)],1)]}}])})],1),_c('delete-dialog',{attrs:{"errorMessage":"¿Está seguro que desea eliminar a este empleado como beneficiario?","errorMessageDetail":"Una vez eliminado perderá todos los beneficios y para recuperarlos deberá darlo de alta nuevamente","showDialog":_vm.showDialogDelete},on:{"closed":function($event){_vm.idBenToDelete = false;
      _vm.showDialogDelete = false;},"confirmDelete":_vm._deleteBeneficiario}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }