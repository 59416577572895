<template>
  <section class="BeneficiariosForm empresas__inner container mt24 mb24">
    <ButtonBackHome />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Tus beneficiarios</h2>
      <div class="empresas_home__text">
        Estos son tus empleados que actualmente cuentan con beneficios.<br />
        Para poder registrar a los miembros de su familia, para que ellos
        también tengan los beneficios en los servicios de Cruz Roja Mexicana
        Chihuahua, tu empleado deberá usar el código de 6 caracteres que se le
        asignó en
        <a
          style="color: #ed1b2e"
          v-text="'https://cruzrojachihuahua.org/#/beneficiario'"
          href="https://cruzrojachihuahua.org/#/beneficiario"
          target="_blank"
        />
      </div>
    </header>
    <v-card
      flat
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="beneficiarios"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <template v-slot:item.codigo="{ item }">
          <span
            v-if="item.familia === 'si'"
            v-text="item.codigo"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon>
            <v-icon v-text="'group'" />
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon v-text="'more_vert'" />
              </v-btn>
            </template>
            <v-list dark>
              <v-list-item @click="idBenToDelete = item.id">
                <v-list-item-title v-text="'Eliminar beneficiario'" />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <delete-dialog
      errorMessage="¿Está seguro que desea eliminar a este empleado como beneficiario?"
      errorMessageDetail="Una vez eliminado perderá todos los beneficios y para recuperarlos deberá darlo de alta nuevamente"
      :showDialog="showDialogDelete"
      @closed="
        idBenToDelete = false;
        showDialogDelete = false;
      "
      @confirmDelete="_deleteBeneficiario"
    />
  </section>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
export default {
  name: "BeneficiariosForm",
  mixins: [standard, forms],
  components: { DeleteDialog },
  data() {
    return {
      search: "",
      showDialogDelete: false,
      headers: [
        { text: "Beneficiario titular", value: "beneficiario" },
        { text: "Programa", value: "programa" },
        { text: "Código", value: "codigo" },
        { text: "Desde", value: "desde" },
        { text: "", value: "actions" },
      ],
      beneficiarios: [],
      idBenToDelete: null,
    };
  },
  methods: {
    _getBeneficiarios() {
      this._getThings("beneficiarios", "beneficiarios");
    },
    _deleteBeneficiario() {
      this._deleteAll(
        "beneficiarios/" + this.idBenToDelete,
        "msg",
        "_handleResponseDelete"
      );
    },
    _handleResponseDelete(res) {
      this.$emit("msg", res.msg);
      this._getBeneficiarios();
      this.showDialogDelete = false;
      this.idBenToDelete = false;
    },
  },
  created() {
    this._getBeneficiarios();
  },
  watch: {
    idBenToDelete(a) {
      if (a) this.showDialogDelete = true;
    },
  },
};
</script>